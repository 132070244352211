import { default as index7bX5qL9w3WMeta } from "/opt/render/project/src/pages/admin/index.vue?macro=true";
import { default as EmailConfirmationG2N2W4BKqtMeta } from "/opt/render/project/src/pages/EmailConfirmation.vue?macro=true";
import { default as FormsPWwfK5EAeMeta } from "/opt/render/project/src/pages/Form.vue?macro=true";
import { default as indexYy8SidDWaAMeta } from "/opt/render/project/src/pages/index.vue?macro=true";
import { default as LoginYWptXulXTzMeta } from "/opt/render/project/src/pages/Login.vue?macro=true";
import { default as EmailVerificationeQTV9KL9ZlMeta } from "/opt/render/project/src/pages/password/EmailVerification.vue?macro=true";
import { default as PasswordReset4IpAirPaZrMeta } from "/opt/render/project/src/pages/password/PasswordReset.vue?macro=true";
import { default as RegistrationsVMN9cT4jbEMeta } from "/opt/render/project/src/pages/Registrations.vue?macro=true";
import { default as indexUnt4loNZMgMeta } from "/opt/render/project/src/pages/Students/index.vue?macro=true";
import { default as SummaryDHuy3znSZmMeta } from "/opt/render/project/src/pages/Students/Summary.vue?macro=true";
import { default as ConfirmPayment3GZGQddeI0Meta } from "/opt/render/project/src/pages/Submission/ConfirmPayment.vue?macro=true";
import { default as index9R20c6c8TnMeta } from "/opt/render/project/src/pages/Submission/index.vue?macro=true";
import { default as paymentpx0VppdffdMeta } from "/opt/render/project/src/pages/Submission/payment.vue?macro=true";
import { default as result1JI98NvyYfMeta } from "/opt/render/project/src/pages/Submission/result.vue?macro=true";
import { default as UserInformations4pgs15RfNMeta } from "/opt/render/project/src/pages/UserInformation.vue?macro=true";
export default [
  {
    name: "admin",
    path: "/admin",
    meta: index7bX5qL9w3WMeta || {},
    component: () => import("/opt/render/project/src/pages/admin/index.vue")
  },
  {
    name: "EmailConfirmation",
    path: "/EmailConfirmation",
    component: () => import("/opt/render/project/src/pages/EmailConfirmation.vue")
  },
  {
    name: "Form",
    path: "/Form",
    meta: FormsPWwfK5EAeMeta || {},
    component: () => import("/opt/render/project/src/pages/Form.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/opt/render/project/src/pages/index.vue")
  },
  {
    name: "Login",
    path: "/Login",
    component: () => import("/opt/render/project/src/pages/Login.vue")
  },
  {
    name: "password-EmailVerification",
    path: "/password/EmailVerification",
    component: () => import("/opt/render/project/src/pages/password/EmailVerification.vue")
  },
  {
    name: "password-PasswordReset",
    path: "/password/PasswordReset",
    component: () => import("/opt/render/project/src/pages/password/PasswordReset.vue")
  },
  {
    name: "Registrations",
    path: "/Registrations",
    meta: RegistrationsVMN9cT4jbEMeta || {},
    component: () => import("/opt/render/project/src/pages/Registrations.vue")
  },
  {
    name: "Students",
    path: "/Students",
    component: () => import("/opt/render/project/src/pages/Students/index.vue")
  },
  {
    name: "Students-Summary",
    path: "/Students/Summary",
    component: () => import("/opt/render/project/src/pages/Students/Summary.vue")
  },
  {
    name: "Submission-ConfirmPayment",
    path: "/Submission/ConfirmPayment",
    meta: ConfirmPayment3GZGQddeI0Meta || {},
    component: () => import("/opt/render/project/src/pages/Submission/ConfirmPayment.vue")
  },
  {
    name: "Submission",
    path: "/Submission",
    meta: index9R20c6c8TnMeta || {},
    component: () => import("/opt/render/project/src/pages/Submission/index.vue")
  },
  {
    name: "Submission-payment",
    path: "/Submission/payment",
    meta: paymentpx0VppdffdMeta || {},
    component: () => import("/opt/render/project/src/pages/Submission/payment.vue")
  },
  {
    name: "Submission-result",
    path: "/Submission/result",
    component: () => import("/opt/render/project/src/pages/Submission/result.vue")
  },
  {
    name: "UserInformation",
    path: "/UserInformation",
    component: () => import("/opt/render/project/src/pages/UserInformation.vue")
  }
]