import validate from "/opt/render/project/src/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@types+node@20.17.6_eslint@9.15.0_jiti@2.4.0__ioredis@5._n5zktxyws2ny46iav2g5iazvhm/node_modules/nuxt/dist/pages/runtime/validate.js";
import manifest_45route_45rule from "/opt/render/project/src/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@types+node@20.17.6_eslint@9.15.0_jiti@2.4.0__ioredis@5._n5zktxyws2ny46iav2g5iazvhm/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  manifest_45route_45rule
]
export const namedMiddleware = {
  auth: () => import("/opt/render/project/src/middleware/auth.ts"),
  form: () => import("/opt/render/project/src/middleware/form.ts"),
  submission: () => import("/opt/render/project/src/middleware/submission.ts")
}