import revive_payload_client_uFdhwwSfKu from "/opt/render/project/src/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@types+node@20.17.6_eslint@9.15.0_jiti@2.4.0__ioredis@5._n5zktxyws2ny46iav2g5iazvhm/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_U0EoQcvilS from "/opt/render/project/src/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@types+node@20.17.6_eslint@9.15.0_jiti@2.4.0__ioredis@5._n5zktxyws2ny46iav2g5iazvhm/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_qdWpa4JVr2 from "/opt/render/project/src/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@types+node@20.17.6_eslint@9.15.0_jiti@2.4.0__ioredis@5._n5zktxyws2ny46iav2g5iazvhm/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_8hBW6qQjg5 from "/opt/render/project/src/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@types+node@20.17.6_eslint@9.15.0_jiti@2.4.0__ioredis@5._n5zktxyws2ny46iav2g5iazvhm/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_LbFNn4KYV4 from "/opt/render/project/src/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@types+node@20.17.6_eslint@9.15.0_jiti@2.4.0__ioredis@5._n5zktxyws2ny46iav2g5iazvhm/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_6dkdcMlx60 from "/opt/render/project/src/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@types+node@20.17.6_eslint@9.15.0_jiti@2.4.0__ioredis@5._n5zktxyws2ny46iav2g5iazvhm/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_7eXr8iqg6x from "/opt/render/project/src/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@types+node@20.17.6_eslint@9.15.0_jiti@2.4.0__ioredis@5._n5zktxyws2ny46iav2g5iazvhm/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_ygEPFF54vE from "/opt/render/project/src/node_modules/.pnpm/@pinia+nuxt@0.5.5_magicast@0.3.5_rollup@4.27.3_typescript@5.6.3_vue@3.5.13_typescript@5.6.3_/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/opt/render/project/src/.nuxt/components.plugin.mjs";
import prefetch_client_rYbpsqqxzr from "/opt/render/project/src/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@types+node@20.17.6_eslint@9.15.0_jiti@2.4.0__ioredis@5._n5zktxyws2ny46iav2g5iazvhm/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_wkkZja5OAj from "/opt/render/project/src/node_modules/.pnpm/@nuxt+icon@1.8.1_magicast@0.3.5_rollup@4.27.3_vite@5.4.11_@types+node@20.17.6_sass@1.81.0_ter_dzroqcv4deqayrhhw54vy7473i/node_modules/@nuxt/icon/dist/runtime/plugin.js";
import primevue_plugin_egKpok8Auk from "/opt/render/project/src/.nuxt/primevue-plugin.mjs";
import plugin_client_K7IETYNkzf from "/opt/render/project/src/node_modules/.pnpm/@primevue+nuxt-module@4.2.2_@babel+parser@7.26.2_magicast@0.3.5_rollup@4.27.3_vue@3.5.13_typescript@5.6.3_/node_modules/@primevue/nuxt-module/dist/runtime/plugin.client.js";
import plugin_wy0B721ODc from "/opt/render/project/src/node_modules/.pnpm/@nuxtjs+device@3.2.4/node_modules/@nuxtjs/device/dist/runtime/plugin.js";
import plugin_xAYYiPXqux from "/opt/render/project/src/node_modules/.pnpm/@nuxtjs+apollo@5.0.0-alpha.11_magicast@0.3.5_rollup@4.27.3_typescript@5.6.3_vue@3.5.13_typescript@5.6.3_/node_modules/@nuxtjs/apollo/dist/runtime/plugin.mjs";
import plugin_Te8qZRdk0Q from "/opt/render/project/src/node_modules/.pnpm/@pinia-plugin-persistedstate+nuxt@1.2.1_@pinia+nuxt@0.5.5_magicast@0.3.5_rollup@4.27.3_typesc_fqtbdvpdqqpt3eysolivid3cg4/node_modules/@pinia-plugin-persistedstate/nuxt/dist/runtime/plugin.js";
import apollo_1ILFkzpYSr from "/opt/render/project/src/plugins/apollo.ts";
import auto_animate_uTpJuTGsMC from "/opt/render/project/src/plugins/auto-animate.ts";
import error_handler_kEP5FliEXj from "/opt/render/project/src/plugins/error-handler.ts";
import maska_UHaKf2z1iQ from "/opt/render/project/src/plugins/maska.ts";
import notifications_Wskzy5ZO71 from "/opt/render/project/src/plugins/notifications.ts";
export default [
  revive_payload_client_uFdhwwSfKu,
  unhead_U0EoQcvilS,
  router_qdWpa4JVr2,
  payload_client_8hBW6qQjg5,
  navigation_repaint_client_LbFNn4KYV4,
  check_outdated_build_client_6dkdcMlx60,
  chunk_reload_client_7eXr8iqg6x,
  plugin_vue3_ygEPFF54vE,
  components_plugin_KR1HBZs4kY,
  prefetch_client_rYbpsqqxzr,
  plugin_wkkZja5OAj,
  primevue_plugin_egKpok8Auk,
  plugin_client_K7IETYNkzf,
  plugin_wy0B721ODc,
  plugin_xAYYiPXqux,
  plugin_Te8qZRdk0Q,
  apollo_1ILFkzpYSr,
  auto_animate_uTpJuTGsMC,
  error_handler_kEP5FliEXj,
  maska_UHaKf2z1iQ,
  notifications_Wskzy5ZO71
]